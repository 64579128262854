<template>
  <CCard>
    <div class="loader" v-if="isLoading">
      <CSpinner color="primary" style="width:4rem; height:4rem;" grow />
    </div>

    <CCardHeader>
      {{ $t("management.statistics.accounting.title") }}

      <CButton
        color="primary"
        square
        size="sm"
        class="float-right ml-1"
        @click="generatePDF"
      >
        {{ $t("buttons.generate_pdf") }}
      </CButton>

      <CButton
        color="primary"
        square
        size="sm"
        class="float-right"
        @click="generateCSV"
      >
        {{ $t("buttons.generate_csv") }}
      </CButton>
    </CCardHeader>
    <CCardBody>
      <CRow align-horizontal="center">
        <CCol sm="3">
          <label>{{ $t("admin.date") }}</label>
          <v-date-picker
            timezone="UTC"
            v-model="range"
            :model-config="modelConfig"
            is-range
            @dayclick="getStatistics"
          >
            <template #default="{ inputValue, togglePopover }">
              <CInput
                :value="`${inputValue.start} — ${inputValue.end}`"
                @click="togglePopover"
                readonly
              />
            </template>
          </v-date-picker>
        </CCol>
      </CRow>
      <CDataTable
        :items="table.items"
        :fields="table.fields"
        :noItemsView="{
          noResults: $t('admin.no_results'),
          noItems: $t('admin.no_items')
        }"
        v-for="(table, index) in tables"
        :key="index"
        border
        striped
        hover
      />
    </CCardBody>
  </CCard>
</template>

<script>
import FileSaver from "file-saver";

export default {
  data() {
    return {
      isLoading: true,
      range: {
        start: new Date(new Date().setHours(0, 0, 0, 0)),
        end: new Date(new Date().setHours(23, 59, 59, 999))
      },
      modelConfig: {
        start: {
          timeAdjust: "00:00:00"
        },
        end: {
          timeAdjust: "23:59:59"
        }
      },
      tables: []
    };
  },

  created() {
    this.getStatistics();
  },

  methods: {
    getStatistics() {
      this.isLoading = true;
      this.$http
        .get(`/api/accountant/statistics/accounting`, {
          params: {
            from: this.range.start.toISOString(),
            to: this.range.end.toISOString(),
            is_pickup: this.$route.meta.pickup || false
          }
        })
        .then(response => {
          this.tables = [];
          response.data.data.forEach(data => {
            const fields = data[0].map((label, key) => ({
              key: key.toString(),
              label
            }));
            const items = data[1];
            this.tables.push({ fields, items });
          });

          this.isLoading = false;
        });
    },
    generateCSV() {
      this.isLoading = true;

      this.$http
        .get(`/api/accountant/statistics/accounting/csv`, {
          params: {
            from: this.range.start.toISOString(),
            to: this.range.end.toISOString()
          },
          responseType: "blob"
        })
        .then(response => {
          FileSaver.saveAs(new Blob([response.data]), "accounting.xlsx");
          this.isLoading = false;
        });
    },
    generatePDF() {
      this.isLoading = true;

      this.$http
        .get(`/api/accountant/statistics/accounting/pdf`, {
          params: {
            from: this.range.start.toISOString(),
            to: this.range.end.toISOString()
          },
          responseType: "blob"
        })
        .then(response => {
          FileSaver.saveAs(new Blob([response.data]), "accounting.pdf");
          this.isLoading = false;
        });
    }
  },

  metaInfo() {
    return {
      title: this.$t("management.statistics.accounting.title")
    };
  }
};
</script>
