<template>
  <CCard>
    <div class="loader" v-if="isLoading">
      <CSpinner color="primary" style="width:4rem; height:4rem;" grow />
    </div>

    <CCardHeader>
      {{ $t("management.statistics.global.title") }}
      <CButton
        color="primary"
        variant="outline"
        square
        size="sm"
        class="float-right"
        @click="createPDF"
      >
        {{ $t("buttons.generate_pdf") }}
      </CButton>
    </CCardHeader>
    <CCardBody>
      <CRow align-horizontal="center">
        <CCol sm="3">
          <label>{{ $t("admin.date") }}</label>
          <v-date-picker
            timezone="UTC"
            v-model="range"
            :model-config="modelConfig"
            is-range
            @dayclick="getStatistics"
          >
            <template #default="{ inputValue, togglePopover }">
              <CInput
                :value="`${inputValue.start} — ${inputValue.end}`"
                @click="togglePopover"
                readonly
              />
            </template>
          </v-date-picker>
        </CCol>
      </CRow>
      <CDataTable
        id="global_table"
        :items="table.items"
        :fields="table.fields"
        :noItemsView="{
          noResults: $t('admin.no_results'),
          noItems: $t('admin.no_items')
        }"
        v-for="(table, index) in tables"
        :key="index"
        border
        striped
        hover
      />
    </CCardBody>
  </CCard>
</template>

<script>
import jsPDF from "jspdf";

export default {
  data() {
    return {
      isLoading: true,
      range: {
        start: new Date(new Date().setHours(0, 0, 0, 0)),
        end: new Date(new Date().setHours(23, 59, 59, 999))
      },
      modelConfig: {
        start: {
          timeAdjust: "00:00:00"
        },
        end: {
          timeAdjust: "23:59:59"
        }
      },
      tables: []
    };
  },

  created() {
    this.getStatistics();
  },

  methods: {
    createPDF() {
      let pdfName =
        "Delovery – detail des encaissements&nbsp; pour&nbsp; la&nbsp; période : " +
        this.range.start.getUTCDate() +
        "." +
        (this.range.start.getUTCMonth() + 1) +
        "." +
        this.range.start.getUTCFullYear() +
        " - " +
        this.range.end.getUTCDate() +
        "." +
        (this.range.end.getUTCMonth() + 1) +
        "." +
        this.range.end.getUTCFullYear();
      var doc = new jsPDF({ orientation: "p", unit: "mm", format: "a4" });

      var html = '<h2>'+pdfName+'<h2>'+document.getElementById("global_table").innerHTML;
      html = html.replace("table-striped ", "");
      html = html.replace("table-hover ", "");
      html = html.replaceAll(
          "<tr ",
          '<tr style="font-size:10pt;letter-spacing: 1px;word-break: break-word;word-wrap: break-word;white-space: break-spaces;" '
      );
      html = html.replaceAll(
          "<td ",
          '<td style="padding: 5px;" '
      );
      doc.html(html, {
        callback: function(doc) {
          doc.save(pdfName + ".pdf");
        },
        margin: [5, 15, 5, 10],
        width: 195,
        windowWidth: 700
      });
    },
    getStatistics() {
      this.isLoading = true;
      this.$http
        .get(`/api/accountant/statistics/global`, {
          params: {
            from: this.range.start.toISOString(),
            to: this.range.end.toISOString()
          }
        })
        .then(response => {
          this.tables = [];
          response.data.data.forEach(data => {
            const fields = data[0].map((label, key) => ({
              key: key.toString(),
              label
            }));
            const items = data[1];
            this.tables.push({ fields, items });
          });

          this.isLoading = false;
        });
    }
  },

  metaInfo() {
    return {
      title: this.$t("management.statistics.global.title")
    };
  }
};
</script>
