<template>
  <CCard>
    <div class="loader" v-if="isLoading">
      <CSpinner color="primary" style="width:4rem; height:4rem;" grow />
    </div>

    <CCardHeader>
      {{ $t("admin.restaurants.sections.dishes.title") }}
      <CButton
        color="primary"
        square
        size="sm"
        class="float-right ml-1"
        @click="generateCSV"
      >
        {{ $t("buttons.generate_xls") }}
      </CButton>
    </CCardHeader>
    <CCardBody>
      <label>{{ $t("admin.restaurant") }}</label>
      <CSelect
          :options="restaurants"
          placeholder="Select restaurant"
          :value.sync="restaurant"
          @update:value="getData"
      />

      <DataTable
        :loading="isLoading"
        :items="items"
        :fields="fields"
        :noItemsView="{
          noResults: $t('admin.no_results'),
          noItems: $t('admin.no_items')
        }"
        border
        striped
        hover
      >
        <template #id="{item}">
          <td class="py-2">
            <a :href="'/admin/restaurants/'+item.restaurant_id+'/sections/'+item.section_id+'/dishes/'+item.id+'/edit'">{{item.id}}</a>
          </td>
        </template>
      </DataTable>
    </CCardBody>
  </CCard>
</template>

<script>
import FileSaver from "file-saver";
import DataTable from "@/components/DataTable";

export default {
  components: {
    DataTable
  },
  data() {
    return {
      isLoading: true,
      restaurant: null,
      restaurants: [],
      items: []
    };
  },

  created() {
    this.getRestaurants();
  },

  computed: {
    fields() {
      return [
        {
          key: "restaurant",
          label: this.$t("admin.restaurants.title")
        },
        { key: "section", label: this.$t("admin.category") },
        { key: "id", label: "ID", _style: "width: 100px" },
        { key: "name", label: this.$t("admin.name") },
        { key: "tax", label: this.$t("admin.restaurants.sections.dishes.tax") }
      ];
    }
  },
  methods: {
    getRestaurants() {
      this.$http.get("/api/management/restaurants").then(response => {
        this.restaurants = response.data.data.map(restaurant => ({
          label: restaurant.name,
          value: restaurant.id
        }));
        this.restaurants.push({label: 'All',
          value: 0})
        if (this.restaurants.length > 0) {
          this.restaurant = this.restaurants[0].value;
          this.getData();
        }
      });
    },
    getData() {
      this.isLoading = true;
      const requests = [
        this.$http.get(
          `/api/accountant/restaurants/${this.restaurant}/dishes`
        )
      ];

      Promise.all(requests).then(responses => {
        this.items = responses[0].data.data;
        this.isLoading = false;
      });
    },
    generateCSV() {
      this.isLoading = true;
      this.$http
        .get(
          `/api/accountant/restaurants/${this.restaurant}/dishes/csv`,
          {
            responseType: "blob"
          }
        )
        .then(response => {
          FileSaver.saveAs(new Blob([response.data]), "dishes.xlsx");
          this.isLoading = false;
        });
    }
  },

  metaInfo() {
    return {
      title: this.$t("management.statistics.restaurants.title")
    };
  }
};
</script>
