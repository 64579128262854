<template>
  <CCard>
    <div class="loader" v-if="isLoading">
      <CSpinner color="primary" style="width:4rem; height:4rem;" grow />
    </div>

    <CCardHeader>
      {{ $t("management.statistics.package_balances.title") }}
    </CCardHeader>
    <CCardBody>
      <CRow align-horizontal="center">
        <CCol sm="3">
          <label>{{ $t("admin.date") }}</label>
          <v-date-picker
            timezone="UTC"
            v-model="range"
            :model-config="modelConfig"
            is-range
            @dayclick="getStatistics"
          >
            <template #default="{ inputValue, togglePopover }">
              <CInput
                :value="`${inputValue.start} — ${inputValue.end}`"
                @click="togglePopover"
                readonly
              />
            </template>
          </v-date-picker>
        </CCol>
      </CRow>
      <CRow
        align-horizontal="center"
        v-for="(packages, restaurant) in items"
        :key="restaurant"
      >
        <CCol sm="6" class="mb-2">
          <h5>{{ restaurant }}</h5>
          <CDataTable
            class="mt-1"
            :items="packages"
            :fields="fields"
            :noItemsView="{
              noResults: $t('admin.no_results'),
              noItems: $t('admin.no_items')
            }"
            border
            striped
            hover
          />
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
      range: {
        start: new Date(new Date().setHours(0, 0, 0, 0)),
        end: new Date(new Date().setHours(23, 59, 59, 999))
      },
      items: [],
      modelConfig: {
        start: {
          timeAdjust: "00:00:00"
        },
        end: {
          timeAdjust: "23:59:59"
        }
      },
      fields: [
        {
          key: "package",
          label: "Package"
        },
        {
          key: "used",
          label: "Used"
        },
        {
          key: "left",
          label: "Left"
        },
        {
          key: "total_purchase",
          label: "Total purchase price"
        },
        {
          key: "total_sale",
          label: "Total sale price"
        },
        {
          key: "difference",
          label: "Difference"
        }
      ]
    };
  },

  created() {
    this.getStatistics();
  },

  methods: {
    getStatistics() {
      this.isLoading = true;
      this.$http
        .get(`/api/accountant/statistics/package-balances`, {
          params: {
            from: this.range.start.toISOString(),
            to: this.range.end.toISOString(),
            is_pickup: this.$route.meta.pickup || false
          }
        })
        .then(response => {
          this.items = response.data.data;
          this.isLoading = false;
        });
    }
  },

  metaInfo() {
    return {
      title: this.$t("management.statistics.package_balances.title")
    };
  }
};
</script>
