<template>
  <CCard>
    <div class="loader" v-if="isLoading">
      <CSpinner color="primary" style="width:4rem; height:4rem;" grow />
    </div>

    <CCardHeader>
      {{ $t("management.statistics.payRestaurants.title") }}
    </CCardHeader>
    <CCardBody>
      <CRow align-horizontal="center">
        <CCol sm="3">
          <label>{{ $t("admin.date") }}</label>
          <v-date-picker
            timezone="UTC"
            v-model="range"
            :model-config="modelConfig"
            is-range
            @input="getStatistics"
          >
            <template #default="{ inputValue, togglePopover }">
              <CInput
                :value="`${inputValue.start} — ${inputValue.end}`"
                @click="togglePopover"
                readonly
              />
            </template>
          </v-date-picker>
        </CCol>
        <CCol sm="3">
          <label>{{ $t("admin.restaurant") }}</label>
          <CSelect
              :options="restaurants"
              placeholder="Select restaurant"
              :value.sync="restaurant"
              @update:value="getStatistics"
          />
        </CCol>
      </CRow>

    </CCardBody>
  </CCard>
</template>

<script>
import FileSaver from "file-saver";

export default {
  data() {
    return {
      isLoading: true,
      range: {
        start: new Date(
          new Date(
            `${new Date().getFullYear()}/${new Date().getMonth()+1}/${new Date().getDate()}`
          ).setHours(0, 0, 0, 0)
        ),
        end: new Date(new Date().setHours(23, 59, 59, 999))
      },
      restaurant: null,
      restaurants: [],
      modelConfig: {
        start: {
          timeAdjust: "00:00:00"
        },
        end: {
          timeAdjust: "23:59:59"
        }
      },
      tables: []
    };
  },

  created() {
    this.getRestaurants();
  },

  methods: {
    getRestaurants() {
      this.$http.get("/api/management/restaurants").then(response => {
        this.restaurants = response.data.data.map(restaurant => ({
          label: restaurant.name,
          value: restaurant.id
        }));
        this.isLoading = false;
      });
    },
    getStatistics() {
      this.isLoading = true;
      if (this.range.start == null) {
        this.isLoading = false;
        return;
      }
      if (this.restaurant == null) {
        this.isLoading = false;
        return;
      }
      this.$http
        .get(`/api/accountant/statistics/payrestaurants/${this.restaurant}`, {
          params: {
            from: this.range.start.toISOString(),
            to: this.range.end.toISOString()
          },
          responseType: "blob"
        })
        .then(response => {
          FileSaver.saveAs(new Blob([response.data]), "restaurant.xlsx");
          this.isLoading = false;
        });
    }
  },

  metaInfo() {
    return {
      title: this.$t("management.statistics.payRestaurants.title")
    };
  }
};
</script>
