<template>
  <CCard>
    <div class="loader" v-if="isLoading">
      <CSpinner color="primary" style="width:4rem; height:4rem;" grow />
    </div>

    <CCardHeader>
      {{ $t("management.statistics.orders.title") }}
    </CCardHeader>
    <CCardBody>
      <CRow align-horizontal="center">
        <CCol sm="3">
          <label>{{ $t("admin.date") }}</label>
          <v-date-picker
            timezone="UTC"
            v-model="range"
            :model-config="modelConfig"
            is-range
            @dayclick="getStatistics"
          >
            <template #default="{ inputValue, togglePopover }">
              <CInput
                :value="`${inputValue.start} — ${inputValue.end}`"
                @click="togglePopover"
                readonly
              />
            </template>
          </v-date-picker>
        </CCol>
      </CRow>
      <CRow
        align-horizontal="center"
        v-for="(block, index) in items"
        :key="index"
      >
        <CCol sm="6">
          <CDataTable
            :items="block"
            :fields="fields"
            :noItemsView="{
              noResults: $t('admin.no_results'),
              noItems: $t('admin.no_items')
            }"
            border
            striped
            hover
          >
            <template #show_key="{item}">
              <td class="py-2">
                {{ $t(`management.statistics.${item[0]}`, [item[1]]) }}
              </td>
            </template>
            <template #show_value="{item}">
              <td class="py-2">
                {{ item.length > 2 ? item[2] : item[1] }}
              </td>
            </template>
          </CDataTable>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
      range: {
        start: new Date(new Date().setHours(0, 0, 0, 0)),
        end: new Date(new Date().setHours(23, 59, 59, 999))
      },
      items: [],
      modelConfig: {
        start: {
          timeAdjust: "00:00:00"
        },
        end: {
          timeAdjust: "23:59:59"
        }
      },
      fields: [
        {
          key: "show_key",
          label: "Metric"
        },
        {
          key: "show_value",
          label: "Value"
        }
      ]
    };
  },

  created() {
    this.getStatistics();
  },

  methods: {
    getStatistics() {
      this.isLoading = true;
      this.$http
        .get(`/api/accountant/statistics/orders`, {
          params: {
            from: this.range.start.toISOString(),
            to: this.range.end.toISOString(),
            is_pickup: this.$route.meta.pickup || false
          }
        })
        .then(response => {
          this.items = response.data.data;
          this.isLoading = false;
        });
    }
  },

  metaInfo() {
    return {
      title: this.$t("management.statistics.orders.title")
    };
  }
};
</script>
